





























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormCheckbox,
  BFormCheckboxGroup
} from 'bootstrap-vue';
import {
  CreateOrderDetailInput,
  MasterDataViewModel,
  OrderDetailViewModel,
  OrderViewModel
} from '@/api/api';
import { quillEditor } from 'vue-quill-editor';
import {
  convertCurrencyToNumber,
  formatCurrency,
  formatDate,
  formatThousand
} from '@/utility/utils';
import { VueGoodTable } from 'vue-good-table';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { mapActions, mapState } from 'vuex';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueGoodTable
  },
  computed: {
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class PrintOrderDetailTable extends Vue {
  @Prop({ type: Object, required: true })
  order!: OrderViewModel;

  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  // Class properties will be component data
  fields = [
    {
      key: 'index',
      label: this.$t('index'),
      thClass: 'th-color',
      thStyle: { width: '2%' }
    },
    {
      key: 'serviceId',
      label: this.$t('serviceSearchlbl'),
      thClass: 'th-color',
      width: '15%'
    },
    {
      key: 'note',
      label: this.$t('Note'),
      thClass: 'th-color',
      thStyle: { width: '18%' }
    },
    {
      key: 'width',
      label: this.$t('Widthlbl'),
      thClass: 'th-color'
    },
    {
      key: 'height',
      label: this.$t('Heightlbl'),
      thClass: 'th-color'
    },
    {
      key: 'quantity',
      label: this.$t('Quantitylbl'),
      thClass: 'th-color'
    },
    {
      key: 'area',
      label: this.$t('Arealbl'),
      thClass: 'th-color'
    },
    // {
    //   key: 'totalArea',
    //   label: this.$t('TotalArealbl'),
    //   thClass: 'th-color'
    // },
    {
      key: 'pricePerUnit',
      label: this.$t('PricePerUnit'),
      thClass: 'th-color',
      thStyle: { width: '7%' }
    },
    {
      key: 'totalPrice',
      label: this.$t('Price'),
      thClass: 'th-color',
      thStyle: { width: '10%' }
    },
    // {
    //   key: 'VAT',
    //   label: 'VAT',
    //   thClass: 'th-color'
    // },
    // {
    //   key: 'finalPrice',
    //   label: this.$t('FinalPrice'),
    //   thClass: 'th-color'
    // },
    {
      key: 'Actions',
      label: '',
      thClass: 'th-color',
      thStyle: { width: '5%' }
    }
  ];

  get hasNote(): boolean {
    return (
      this.order.orderDetails?.findIndex((s) => s.note && s.note !== '') !== -1
    );
  }

  get colSpanNumber(): number {
    let colspan = 5;
    colspan = colspan + (this.hasNote ? 1 : 0);

    return colspan;
  }

  get totalPrice(): number {
    let price = 0;
    this.order?.orderDetails?.forEach((s) => (price += s.total));
    const total =
      convertCurrencyToNumber(this.order.shippingFee) +
      (convertCurrencyToNumber(this.order.vatAmount) / 100) * price +
      price -
      convertCurrencyToNumber(this.order.couponAmount);
    return total < 0 ? 0 : total;
  }

  get remainAmount(): number {
    return this.totalPrice - convertCurrencyToNumber(this.order.depositAmount);
  }

  mounted() {
    if (!this.masterData) {
      this.fetchMasterData();
    }
  }

  getServiceName(orderDetail: OrderDetailViewModel) {
    const productName = orderDetail.serviceName;
    if (
      orderDetail.categoryId &&
      orderDetail.categoryId === this.masterData.catalogueCategoryId
    ) {
      return `<div><div class='row mx-0'><div class='col-sm-8 px-0'>${orderDetail.serviceName}</div> <div class='col-sm-4 px-0'>- ${orderDetail.totalPage} Trang</div></div>
              <div class='row mx-0'><div class='col-sm-8 px-0'>- ${orderDetail.catalogueProductName}</div> <div class='col-sm-4 px-0'>- ${orderDetail.catalogueSizeName}</div></div>
              <div class='row mx-0'><div class='col-sm-8 px-0'> - ${orderDetail.polymeCoverTypeName}</div></div></div>`;
    }

    if (
      orderDetail.processingSettingModels &&
      orderDetail.processingSettingModels.length > 0
    ) {
      let productNameCell = `<div><div class='row mx-0'><div class='col-sm-6 px-0'>${productName}</div>`;

      orderDetail.processingSettingModels?.forEach((item) => {
        productNameCell += `<div class='col-sm-6 px-0'>- ${
          item?.processingSettingModelItems
            ? item.processingSettingModelItems[0].name
            : ''
        } </div>`;
      });
      return productNameCell + '</div>';
    }

    return `<div>
              <div class='row mx-0'>
                <div class='col-sm-6 px-0'>
                  ${productName}</div>
                <div class='col-sm-6 px-0'>
              ${
                orderDetail.polymeCoverTypeName
                  ? '- ' + orderDetail.polymeCoverTypeName
                  : ''
              }</div>
              <div class='col-sm-6 px-0'>
              ${
                orderDetail.processingTypeName
                  ? '- ' + orderDetail.processingTypeName
                  : ''
              }</div></div>
              ${
                orderDetail.processingTypeName2
                  ? '- ' + orderDetail.processingTypeName2
                  : ''
              }</div>`;
  }

  isCatalogueItem(orderDetail: OrderDetailViewModel) {
    return orderDetail.categoryId === this.masterData.catalogueCategoryId;
  }

  // Methods will be component methods
  formatCurrency = formatCurrency;
  formatThousand = formatThousand;
  formatDate = formatDate;
}
