






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormCheckbox,
  BFormCheckboxGroup
} from 'bootstrap-vue';
import {
  CreateOrderDetailInput,
  MasterDataViewModel,
  OrderDetailStatus,
  OrderDetailViewModel,
  OrderViewModel
} from '@/api/api';
import { quillEditor } from 'vue-quill-editor';
import { formatCurrency, formatDate, formatThousand } from '@/utility/utils';
import { VueGoodTable } from 'vue-good-table';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { mapActions, mapState } from 'vuex';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueGoodTable
  },
  computed: {
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class PrintOrderProducingTable extends Vue {
  @Prop({ type: Object, required: true })
  order!: OrderViewModel;

  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  // Class properties will be component data
  fields = [
    {
      field: 'index',
      label: this.$t('No.'),
      sortable: false,
      width: '3%'
    },
    {
      field: 'serviceName',
      label: this.$t('ServiceName'),
      sortable: false,
      width: '20%'
    },
    {
      field: 'status',
      label: this.$t('Status'),
      sortable: false
    },
    // {
    //   field: 'employee',
    //   label: this.$t('Employee'),
    //   sortable: false
    // },
    {
      field: 'width',
      label: this.$t('Widthlbl'),
      sortable: false,
      width: '8%'
    },
    {
      field: 'height',
      label: this.$t('Heightlbl'),
      sortable: false,
      width: '8%'
    },
    // {
    //   field: 'area',
    //   label: this.$t('Arealbl'),
    //   sortable: false
    // },
    {
      field: 'quantity',
      label: this.$t('Quantity'),
      sortable: false,
      width: '8%'
    },
    // {
    //   field: 'totalArea',
    //   label: this.$t('TotalArealbl'),
    //   sortable: false
    // },
    // {
    //   field: 'pricePerUnit',
    //   label: this.$t('PricePerUnit'),
    //   sortable: false
    // },
    // {
    //   field: 'vatAmount',
    //   label: this.$t('Vatlbl'),
    //   sortable: false
    // },
    // {
    //   field: 'total',
    //   label: this.$t('TotalPrice'),
    //   sortable: false
    // },
    {
      field: 'note',
      label: this.$t('Note'),
      sortable: false,
      width: '30%'
    }
  ];

  mounted() {
    if (!this.masterData) {
      this.fetchMasterData();
    }
  }

  getStatus(status) {
    return this.$t(OrderDetailStatus[status]);
  }

  getServiceName(orderDetail: OrderDetailViewModel) {
    const productName = orderDetail.serviceName;
    if (
      orderDetail.categoryId &&
      orderDetail.categoryId === this.masterData.catalogueCategoryId
    ) {
      return `<div><div class='row mx-0'><div class='col-sm-8 px-0'>${orderDetail.serviceName}</div> <div class='col-sm-4 px-0'>- ${orderDetail.totalPage} Trang</div></div>
              <div class='row mx-0'><div class='col-sm-8 px-0'>- ${orderDetail.catalogueProductName}</div> <div class='col-sm-4 px-0'>- ${orderDetail.catalogueSizeName}</div></div>
              <div class='row mx-0'><div class='col-sm-8 px-0'> - ${orderDetail.polymeCoverTypeName}</div></div></div>`;
    }

    if (
      orderDetail.processingSettingModels &&
      orderDetail.processingSettingModels.length > 0
    ) {
      let productNameCell = `<div><div class='row mx-0'><div class='col-sm-6 px-0'>${productName}</div>`;

      orderDetail.processingSettingModels?.forEach((item) => {
        productNameCell += `<div class='col-sm-6 px-0'>- ${
          item?.processingSettingModelItems
            ? item.processingSettingModelItems[0].name
            : ''
        } </div>`;
      });
      return productNameCell + '</div>';
    }

    return `<div>
              <div class='row mx-0'>
                <div class='col-sm-6 px-0'>
                  ${productName}</div>
                <div class='col-sm-6 px-0'>
              ${
                orderDetail.polymeCoverTypeName
                  ? '- ' + orderDetail.polymeCoverTypeName
                  : ''
              }</div>
              <div class='col-sm-6 px-0'>
              ${
                orderDetail.processingTypeName
                  ? '- ' + orderDetail.processingTypeName
                  : ''
              }</div></div>
              ${
                orderDetail.processingTypeName2
                  ? '- ' + orderDetail.processingTypeName2
                  : ''
              }</div>`;
  }

  isCatalogueItem(orderDetail: CreateOrderDetailInput) {
    return orderDetail.categoryId === this.masterData.catalogueCategoryId;
  }

  // Methods will be component methods
  formatCurrency = formatCurrency;
  formatThousand = formatThousand;
  formatDate = formatDate;
}
